.carousel-container-banners {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-content-banners {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  cursor: grab;
  user-select: none;
}

.carousel-content-banners::-webkit-scrollbar {
  display: none;
}

.carousel-content-banners:active {
  cursor: grabbing;
}

.carousel-item-banners {
  flex: 0 0 calc(100% / 4);
  padding: 0 5px;
  box-sizing: border-box;
}

.carousel-item-banners img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  pointer-events: none;
  cursor: pointer;
}

.carousel-item-banners:hover img {
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel-control-banners {
  position: absolute;
  top: 44%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10;
  transition: background-color 0.3s ease, opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.carousel-control-banners:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel-control-banners.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.carousel-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-dot.active {
  background-color: #333;
}

@media (max-width: 767px) {
  .carousel-item-banners {
    flex: 0 0 70%;
  }

  .carousel-control-banners {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}