.ordersComponent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.tabNavigation {
  display: flex;
  margin-bottom: 20px;
}

.tabButton {
  background-color: #f1f1f1;
  border: none;
  border-radius: 2px;
  padding: 10px 20px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tabButton:hover {
  background-color: #e2e2e2;
}

.tabButton.active {
  background-color: #3e67be;
  color: #fff;
}

.ordersList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
