.explore-container {
  position: relative;
  margin-right: 10%;
}

.arrow-icon {
  color: #4f6cbb;
}

.explore-button {
  display: flex;
  align-items: center;
  padding: 10px 45px;
  background-color: #4f6cbb;
  color: #ffffff;
  border: 1px solid #4f6cbb;
  cursor: pointer;
  border-radius: 30px;
  font-size: 16px;
  transition: background-color 0.4s ease, transform 0.4s ease;
}

.explore-button:hover {
  background-color: #3a53a4;
  transform: translateX(5px);
}

.explore-button .fa-compass {
  margin-right: 8px;
}

.explore-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.explore-menu-overlay.visible {
  opacity: 1;
}

.explore-menu {
  display: flex;
  position: relative;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  border-radius: 10px;
  transform: translateY(-20px);
  opacity: 0;
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}

.explore-menu.visible {
  transform: translateY(0);
  opacity: 1;
}

.explore-menu.mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
}

/* Dropdown container styles */
.dropdown-container {
  position: relative;
}

/* Dropdown menu items */
.dropdown-menu {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition-delay: 0s;
  margin-bottom: 10px;
}

.dropdown-menu.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Staggered animation delay */
.dropdown-menu:nth-child(1) {
  transition-delay: 0.1s;
}

.dropdown-menu:nth-child(2) {
  transition-delay: 0.2s;
}

.dropdown-menu:nth-child(3) {
  transition-delay: 0.3s;
}

.dropdown-menu:nth-child(4) {
  transition-delay: 0.4s;
}

.dropdown-menu:nth-child(5) {
  transition-delay: 0.5s;
}

.vertical-menu, .horizontal-menu {
  height: 520px;
}

.vertical-menu-item-div, .horizontal-menu-item-div {
  height: 420px;
  overflow-y: scroll;
}

.parent-category img, .horizontal-menu-item-div img, .mobile-menu-item img {
  transition: transform 0.6s ease, filter 0.4s ease;
}

.parent-category:hover img, .horizontal-menu-item-div button:hover img, .mobile-menu-item button:hover img {
  transform: scale(1.1);
  filter: brightness(1.2);
}

.vertical-menu, .horizontal-menu, .mobile-menu {
  padding: 20px;
  min-width: 300px;
  border-right: 1px solid #e0e0e0;
  background-color: #f9f9f9;
}

.vertical-menu h3, .horizontal-menu h3 {
  margin-bottom: 10px;
}

.mobile-menu h3 {
  margin: 0px;
}

.vertical-menu p, .horizontal-menu p, .mobile-menu p {
  margin-bottom: 20px;
  color: #666;
}

.vertical-menu button, .horizontal-menu button, .mobile-menu-item button {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 10px;
  margin: 10px 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.6s, transform 0.4s ease;
  font-weight: 550;
}

.vertical-menu button:hover, .horizontal-menu button:hover, .mobile-menu-item button:hover {
  background-color: #f9f9f9;
  transform: translateX(5px);
}

.category-thumbnail {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  object-fit: contain;
}

.arrow-icon {
  margin-left: auto;
}

.sub-menu {
  padding-left: 5px;
}

.close-button, .back-button {
  position: fixed;
  top: 20px;
  right: 18px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 1002;
}

.back-button {
  left: 10px;
  right: 100%;
}

.vertical-menu button.active, .horizontal-menu button.active, .mobile-menu-item button.active {
  background-color: #e0f7fa;
}

.horizontal-menu button, .mobile-menu-item button {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #4f6cbb;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  margin-left: 8px;
  font-size: 14px;
  color: #4f6cbb;
}

@media (max-width: 768px) {
    .explore-button {
      padding: 10px 35px;
      margin: 5px 2px 10px 5px;
      display: flow;
      border-radius: 8px;
  }

  .explore-container {
    margin-right: 2%;
  }

  .explore-menu {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }

  .vertical-menu, .horizontal-menu, .mobile-menu {
    border-right: none;
  }
}

.scrollable-container {
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 10px;
}

.vertical-menu-item-div.scrollable-container {
  max-height: 60vh;
}

.horizontal-menu-item-div.scrollable-container {
  max-height: 50vh;
}

.hover {
  background-color: #f0f0f0;
}
