.courseBanner {
    flex: 1 1 300px;
    position: relative;
    overflow: hidden;
    padding: 8px;
    background-color: #cee5f5;
}

.coursebannerspadding{
    padding: 0px;
}

.courseBannerImage {
    width: 70%;
    object-fit: cover;
    margin-left: 30px;
    border: 13.888px solid #FFF;
    padding: 1px;
    border-radius: 35px;
    box-shadow: 0px 28.904px 8.43px 0px rgba(194, 194, 194, 0.00), 0px 19.27px 7.226px 0px rgba(194, 194, 194, 0.01), 0px 10.839px 6.022px 0px rgba(194, 194, 194, 0.05), 0px 4.817px 4.817px 0px rgba(194, 194, 194, 0.09), 0px 1.204px 2.409px 0px rgba(194, 194, 194, 0.10);
}

@media (max-width: 768px) {
    .courseBannerImage {
        width: 100%;
        object-fit: cover;
        margin-left: 1px;
        border: 10px solid #FFF;
        padding: 1px;
        border-radius: 35px;
    }
  }