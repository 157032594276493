.footer {
    background-color: #f9f9f9;
    padding: 10px 10px 10px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #eaeaea;
    transition: background-color 0.3s ease;
    margin-top: 35px;
}

.footer-columns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
}

.footer-column {
    flex: 1;
    padding: 10px;
}

.footer-column h4 {
    font-size: 21px;
    margin-bottom: 10px;
    color: #2f2f2f;
    font-weight: 800;
}

.footer-column ul {
    list-style-type: none;
    padding: 0;
}

.footer-column li {
    margin-bottom: 8px;
}

.footer-column a {
    text-decoration: none;
    color: #4267B2;
    transition: color 0.3s ease;
}

.footer-column a:hover {
    color: #ccc;
}

.logo {
    width: 70px;
}

.social-icons {
    display: flex;
    gap: 40px;
}

.social-icons a {
    font-size: 28px;
}

@media (max-width: 768px) {
    .footer-heading {
        font-size: 22px;
    }

    .footer {
        padding: 0px 0px 0px 0px;
    }

    .footer-columns {
        flex-direction: column;
        align-items: center;
    }

    .footer-column {
        width: 100%;
    }
}