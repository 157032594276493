.order-summary h2 {
  font-size: 18px;
  margin-bottom: 15px;
}

.order-item {
  display: flex;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 15px;
}

.order-image {
  width: 200px;
  height: 140px;
  margin-right: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.order-image img {
  max-width: 100%;
  max-height: 100%;
}

.order-details h3 {
  font-size: 16px;
  margin: 15px 0 10px 0;
  color: #333;
}

.order-info {
  display: inline;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.order-details p {
  font-size: 14px;
  margin: 5px 0;
  color: #666;
}

.order-span{
  margin: 3px;
}