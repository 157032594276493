.sendOtpContainer {
  text-align: center;
  width: 100%;
  padding: 20px;
  position: relative;
}

.logo {
  display: flex;
  justify-content: center;
}

.logo img {
  width: 60px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.progressBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.progressBar::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ddd;
  z-index: 0;
}

.step {
  position: relative;
  z-index: 1;
  background-color: #acacac;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #ffffff;
}

.step.active {
  background-color: #3e67be;
  border: none;
  color: #ffffff;
}

.step.completed {
  border-color: #3e67be;
  color: #3e67be;
}

.stepLabel {
  margin-top: 8px;
  font-size: 12px;
  color: #777;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.stepLabel span {
  width: 33%;
}

.stepLabel .active {
  color: #3e67be;
}

.stepLabel .completed {
  color: #3e67be;
}

.loginStep {
  width: 100%;
  margin-top: 20px;
}

.loginStep label {
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
}

.mobileInputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.mobileInputContainer select {
  width: 30%;
  padding: 10px;
  margin-right: 10px;
  font-size: 14px;
}

.mobileInputContainer input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-left: 10px;
  border: 1px solid #ddd;
}

.separator {
  margin: 20px 0;
  font-size: 14px;
  color: #666666;
}

.errorMessage {
  color: red;
  margin-top: 10px;
}

.GetOtpButton {
  cursor: pointer;
  background-color: #3e67be;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  margin: 10px 0;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
}

.googleSignupButton {
  background-color: transparent;
  color: #333333;
  border: 1px solid #acacac;
  padding: 13px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
}

.LabelOfMobileNumber {
  color: #666666;
}

.Step1Text {
  text-align: left;
  padding-left: 12px;
}

.Step3Text {
  text-align: right;
}
