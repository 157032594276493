.add-ons {
  margin: 20px 0;
  padding: 1px;
  border-radius: 10px;
}
 
.add-ons h2 {
  color: #4f6cbb;
  margin-bottom: 18px;
  font-size: 24px;
  font-weight: 550;
}
 
.add-ons-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  padding: 10px 0;
}
 
.add-on-container {
  display: flex;
  gap: 2px;
  transition: transform 0.3s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  max-width: 85%;
}
 
.add-on-item {
  padding: 20px;
  border: 1px solid #e0e0e0;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 8px;
  min-width: calc(50% - 10px);
  flex: 0 0 calc(50% - 10px);
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
 
.add-on-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
 
.add-on-info {
  display: flex;
  align-items: flex-start;
}
 
.info-icon {
  margin-right: 15px;
  font-size: 20px;
  color: #4267B2;
}
 
.add-on-info h3 {
  margin: 0 0 10px;
  color: #333;
  font-size: 18px;
}
 
.add-on-info p {
  margin: 0 0 10px;
  color: #666;
  font-size: 14px;
}
 
.price {
  font-weight: bold;
  color: #4267B2;
  font-size: 16px;
}
 
.add-button {
  background-color: #4267B2;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}
 
.scroll-button {
  background-color: #4267B2;
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: background-color 0.3s ease;
}
 
.scroll-button:hover {
  background-color: #365899;
}
 
.scroll-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
 
@media (max-width: 768px) {
  .add-on-item {
    min-width: calc(100% - 20px);
    flex: 0 0 calc(100% - 20px);
  }
 
  .add-on-container {
    width: calc(100% - 80px);
  }
 
  .scroll-button {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}
 
/* Additional Changes in Addons Layout  */
 
.add-ons-viewall {
  display: block;
  text-align: right;
  color: #4f6cbb;
  font-weight: 500;
  cursor: pointer;
  /* text-decoration: underline; */
  margin-bottom: 10px;
  margin-right: 25px;
}
 
.add-ons-viewall:hover {
  color: #053971;
}
 
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
 
.popup {
  background: white;
  padding: 20px;
  width: 60%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
}
 
.close-popup {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: #4f6cbb;
}
 
.close-popup:hover {
  color: red;
}
 
.popup-content {
  max-height: 400px;
  overflow-y: auto;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
 
.popup-content {
  max-height: 400px;
  overflow-y: auto;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  padding: 10px;
}
 
.add-on-item {
  /* background: linear-gradient(135deg, #fff, #eee); */
  border-radius: 12px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #fff;
}
 
.add-on-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}
 
.info-icon {
  color: #007bff;
  font-size: 22px;
  margin-bottom: 8px;
}
 
.add-on-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
  color: #333;
}
 
.add-on-item p {
  font-size: 18px;
  color: #242424;
  margin: 3px 0;
}
 
.price {
  font-size: 16px;
  font-weight: bold;
  color: #28a745;
}
 
.add-button {
  background: #4f6cbb;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.2s ease-in-out;
  margin-top: 10px;
  width: 100%;
}
 
.add-button:hover {
  background: #4267b2;
}
 
.add-button.selected {
  background: #dc3545;
}
 
.add-button.selected:hover {
  background: #b02a37;
}