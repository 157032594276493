/* added ribon */
.dla-ribbon {
  position: absolute;
  background-image: url(https://www.drishtiias.com/images/uploads/1727156304_Group%201321315019@2x.png);
  background-repeat: no-repeat;
  right: -2px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 81px;
  height: 80px;
  text-align: right;
}

.dla-ribbon span {
  font-size: 15px;
  font-weight: bold;
  color: #FFF;
  text-transform: capitalize;
  text-align: center;
  line-height: 17px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  position: absolute;
  top: 18px;
  right: -21px;
  animation: dla-ribbon-blinker 1s linear infinite;
}

@keyframes dla-ribbon-blinker {
  50% {
    opacity: 0.5;
  }
}
/* end  */
.course-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.course-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.category-btn{
  color: #4267b2;
  border: 1px solid #4267b2;
  background-color: #f1f5ff;
  text-decoration: none;
  font-weight: 400;
  padding: 5px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 14px;
}

.category-main-div{
  float: right;
  justify-content: center;
}

.view-all {
  color: #4267b2;
  background-color: #fff;
  text-decoration: none;
  padding: 8px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 14px;
}

.view-all:hover {
  background-color: #4267B2;
  color: white;
  transition: all 0.6s ease;
}

.course-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, 0fr));
  gap: 15px;
}

.course-card {
  border: 1px solid #e0e0e0;
  border-radius: 18px;
  overflow: hidden;
  background-color: #fff;
  position: relative;
}

.course-banner {
  position: relative;
}

.course-banner img {
  width: 100%;
  height: auto;
}

.course-info {
  padding: 15px;
}

.course-info h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
}

.course-info p {
  margin: 5px 0;
  font-size: 14px;
}

.course-type {
  font-weight: 450;
  margin: 5px 0;
  font-size: 16px;
}

.course-details {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  font-size: 15px;
  color: #666;
}

.course-pricing {
  margin: 10px 0;
}

.course-discounted-price {
  font-weight: bold;
  font-size: 18px;
  margin-right: 10px;
}

.course-original-price {
  text-decoration: line-through;
  color: #666;
}

.course-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.course-view-details,
.course-buy-now {
  padding: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.course-view-details {
  background-color: white;
  border: 1px solid #4267b2;
  color: #4267b2;
  width: 45%;
}

.course-buy-now {
  background-color: white;
  border: 1px solid #4267b2;
  color: #4267b2;
  width: 45%;
}

.course-view-details:hover {
  background-color: #4267b2;
  color: white;
}

.course-buy-now:hover {
  background-color: #4267b2;
  color: white;
}


@media (max-width: 768px) {
  .course-grid {
    grid-template-columns: 1fr;
  }
}