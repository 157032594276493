.informationCourse {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    border-radius: 4px;
  }

  .infoRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 3px;
    border-radius: 5px;
  }
  
  .headingText {
    font-weight: bold;
    color: #333;
    margin-right: 10px;
  }
  
  @media (max-width: 768px) {
    .informationCourse {
        flex-direction: column;
      }

    .infoRow {
      align-items: flex-start;
    }
  
    .headingText {
      margin-bottom: 5px;
    }
  }
  