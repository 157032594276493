.heroSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 100px;
  background: linear-gradient(135deg, #f0f4ff 0%, #dfeaff 50%, #cce1ff 100%);
  transition: background 0.5s ease-in-out;
  border-radius: 8px;
  margin-top: 10px;
}

.content {
  max-width: 50%;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.2;
  color: #333;
}

.trusted {
  color: #3b82f6;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease;
}

.trusted:hover {
  color: #2563eb;
}

.subtitle {
  margin-top: 20px;
  font-size: 1.25rem;
  color: #555;
  font-weight: 400;
}

.imageWrapper {
  max-width: 45%;
  display: flex;
  justify-content: flex-end;
}

.instructorImage {
  max-width: 50%;
  height: auto;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  border-radius: 10px;
}

.instructorImage:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .heroSection {
    flex-direction: column;
    padding: 30px;
  }

  .content {
    max-width: 100%;
    text-align: center;
  }

  .title {
    font-size: 1.5rem;
  }

  .imageWrapper {
    margin-top: 20px;
    justify-content: center;
  }

  .instructorImage {
    max-width: 100%;
  }
}
