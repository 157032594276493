.added-addons {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .added-addons h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .added-addon-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 8px;
  }
  
  .addon-info {
    display: flex;
    flex-direction: column;
  }
  
  .addon-name {
    font-weight: bold;
    font-size: 14px;
  }
  
  .addon-price {
    color: #4267B2;
    font-size: 14px;
  }
  
  .remove-addon {
    background: none;
    border: none;
    cursor: pointer;
    color: #888;
  }
  
  .remove-addon:hover {
    color: #ff4d4d;
  }