.otpInputContainer {
    display: flex;
    justify-content: space-between;
  }
  
  .otpInput {
    margin-top: 10px;
    margin-right: 42px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    text-align: center;
  }
  
  .otpInput:last-child {
    margin-right: 0;
  }
  