.dropdown {
    position: relative;
    display: inline-block;
    width: 18%;
  }
  
  .dropdownToggle {
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .dropdownToggle img {
    width: 20px;
    height: auto;
    margin-left: 8px;
  }
  
  .dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 98%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdownItem {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .dropdownItem:hover {
    background-color: #f0f0f0;
  }
  
  .dropdownItem img {
    width: 20px;
    height: auto;
    margin-left: 8px;
  }
  