.payment-page {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .payment-page h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .payment-content {
    display: flex;
    gap: 20px;
  }
  
  .left-column {
    flex: 1;
  }
  
  .right-column {
    flex: 1;
  }
 
  .right-column h2 {
    font-size: 18px;
    margin-bottom: 15px;
}

  @media (max-width: 768px) {
    .payment-content {
      flex-direction: column;
    }
    
    .order-image{
      margin-right: 10px;
      width: 50%;
      height: 100%;
    }

    .left-column, .right-column {
      width: 100%;
    }
  }