@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.appParentDiv{
  padding: 0px 100px 0px 100px;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: #1f1f1f;
}

.logged-in {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 20px ;
  transition: padding 0.3s ease;
}

@media (max-width: 768px) {
  .appParentDiv{
    padding: 0px 0px 0px 0px;
  }
  .logged-in {
    padding-left: 0px;
    padding-right: 0px;
    transition: margin-left 0.3s ease;
  }

}

footer {
  transition: margin-left 0.3s ease;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #4267B2;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5e739e;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}