.bill-summary {
    margin-top: 20px;
  }
  
  .bill-summary h2 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .bill-item, .bill-total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .bill-total {
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
    border-top: 1px solid #ddd;
    padding-top: 15px;
  }
  
  .continue-btn {
    background-color: #4267B2;
    color: white;
    border: none;
    padding: 12px 0;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    margin-top: 20px;
  }

  @media (max-width: 576px) {
    .bill-item, .bill-total {
      font-size: 14px;
    }
  
    .bill-total {
      font-size: 16px;
    }
  }