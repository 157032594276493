.login-container {
    display: flex;
    height: 100vh;
  }
  
  .login-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
  }
  
  .login-image img {
    max-width: 100%;
    height: auto;
  }
  
  .login-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .login-form-internal-div{
    padding: 20px 90px 20px 90px;
  }

  @media only screen and (min-width: 900px) {
    .login-form {
      /* padding: 20px 90px 20px 90px; */
      box-shadow: -4px 0 8px -4px rgba(0, 0, 0, 0.1);
    }
  }
  @media only screen and (max-width: 900px) {
    .login-image{
      display: none;
    }
  }
  .login-form h1 {
    margin-bottom: 20px;
  }
  
  .login-form .login-step, .login-form .verification-step {
    width: 100%;
  }

  