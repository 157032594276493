@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.courseContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  max-width: 1200px;
  margin: 0 auto;
}

.courseCard {
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  animation: fadeIn 0.5s ease-out;
}

.courseCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.bannerSection {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f5fafe;
  transition: background-color 0.3s ease;
}

.bannerImage {
  max-width: 30%;
  object-fit: cover;
  border-radius: 12px;
  margin-right: 20px;
  transition: transform 0.3s ease-in-out;
}

.bannerImage:hover {
  transform: scale(1.05);
}

.bannerContent {
  flex-grow: 1;
}

.bannerContent h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
  transition: color 0.3s ease;
}

.courseDetails {
  padding: 16px;
  margin-top: 12px;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #666;
  margin-bottom: 8px;
}

.paymentStatus {
  padding: 6px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.complete {
  background: #28a745;
  color: white;
}

.ongoing {
  background: #ffc107;
  color: black;
}

.progressText {
  font-size: 16px;
  color: #333;
  font-weight: 700;
  margin-bottom: 10px;
}

.progressBarContainer {
  background: #e0e0e0;
  border-radius: 10px;
  height: 8px;
  width: 100%;
  overflow: hidden;
}

.progressBar {
  height: 8px;
  background: #007bff;
  width: 0%;
  transition: width 0.3s ease-in-out;
}

.progressSteps {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progressStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
}

.progressStep:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;
  z-index: 1;
  transition: background-color 0.3s ease;
}

.stepDot {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #e0e0e0;
  z-index: 2;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepInnerDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e0e0e0;
  transition: all 0.3s ease;
}

.progressStep.completed .stepDot {
  border-color: #4caf50;
}

.progressStep.completed .stepInnerDot {
  background-color: #4caf50;
}

.progressStep.completed::after {
  background-color: #4caf50;
}

.progressStep.upcoming .stepDot {
  border-color: #ff9800;
}

.progressStep.upcoming .stepInnerDot {
  background-color: #ff9800;
}

.progressStep.pending .stepDot {
  border-color: #e0e0e0;
}

.progressStep.pending .stepInnerDot {
  background-color: #e0e0e0;
}

.actionButtons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.actionButton {
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.actionButton:hover {
  background-color: #4f6cbb;
  transform: translateY(-2px);
}
.viewDetailsContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px; /* Increased space */
  padding-top: 10px; /* Additional padding for separation */
  border-top: 1px solid #ddd; /* Light separator for clarity */
}

.viewDetailsButton {
  background-color: #4f6cbb;
  color: white;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  margin-left:5px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.viewDetailsButton:hover {
  background-color: #4f6cbb;
  transform: translateY(-2px);
}

.viewDetailsButton:active {
  background-color: #2E7D32;
  transform: scale(0.98);
}


@media (max-width: 768px) {
  .actionButtons {
    flex-direction: column;
  }

  .actionButton {
    width: 100%;
    justify-content: center;
  }
}
