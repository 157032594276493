.discount-coupons {
  margin-top: 20px;
}

.discount-coupons h2 {
  font-size: 18px;
  margin-bottom: 15px;
}

.coupons-container {
  display: flex;
  gap: 15px;
}

.coupon {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  flex: 1;
}

.coupon-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.coupon-icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.coupon h3 {
  font-size: 16px;
  margin: 0 0 5px 0;
  color: #4267B2;
}

.coupon p {
  font-size: 14px;
  margin: 0;
}

.coupon button {
  background-color: transparent;
  color: #4267B2;
  border: 1px solid #4267B2;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.coupon-icon {
  width: 24px;
  height: 24px;
  color: #4267B2;
  margin-right: 8px;
}

.coupon.applied {
  background-color: #e6f3f3;
  border: 1px solid #4267B2;
}

.coupon.applied button {
  background-color: #4267B2;
  color: white;
  /* cursor: not-allowed; */
}

.No-Coupon-Web{
  width: 550px;
  height: 180px;
}

@media (max-width: 668px) {

  .No-Coupon-Web{
    width: 335px;
    height: 140px;
  }

  .coupons-container {
    flex-direction: column;
  }

  .coupon {
    margin-bottom: 15px;
  }
}