.container {
    max-width: 1200px;
    margin: 0rem auto 2rem;
    padding: 0 2rem;
}

.aboutCon {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s ease forwards;
}

.h3 {
    color: #2c3e50;
    margin-bottom: 1rem;
    font-size: 2rem;
    cursor: pointer;
    text-align: left;
}

.des {
    text-align: justify;
    margin-bottom: 1rem;
    opacity: 0;
    animation: fadeIn 1s ease forwards 0.5s;
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .container {
        margin-top: 1rem;
    }
}