:root {
  --primary-color: #4267B2;
  --hover-color: #e8f0fe;
  --text-color: #5f6368;
  --active-text-color: #202124;
  --transition-speed: 0.3s;
  --gradient-start: #fff;
  --gradient-end: #fff;
}

.sidenav {
  width: 60px;
  height: 100vh;
  background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));
  position: fixed;
  left: 0;
  top: auto;
  transition: width var(--transition-speed) ease-in-out, box-shadow var(--transition-speed);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  z-index: 998;
  border-radius: 4px;
  box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
}

.sidenav.expanded {
  width: 240px;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  color: var(--text-color);
  white-space: nowrap;
  transition: background-color var(--transition-speed) ease, color var(--transition-speed) ease;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.nav-item:hover,
.nav-item.active {
  background-color: var(--hover-color);
  color: var(--primary-color);
  border-radius: 10px;
}

.nav-item .icon {
  width: 24px;
  text-align: center;
  margin-right: 15px;
  font-size: 18px;
  transition: transform var(--transition-speed) ease;
}

.nav-item:hover .icon {
  transform: scale(1.1);
}

.nav-text {
  opacity: 0;
  transition: opacity var(--transition-speed) ease, transform var(--transition-speed) ease;
  font-size: 14px;
  transform: translateX(-10px);
}

.sidenav.expanded .nav-text {
  opacity: 1;
  transform: translateX(0);
}

.chevron-right {
  position: absolute;
  right: 10px;
  opacity: 0;
  transition: opacity var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.nav-item:hover .chevron-right {
  opacity: 1;
  transform: translateX(-5px);
}

.toggle-button {
  margin-top: auto;
  background: var(--primary-color);
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
  transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
  align-self: flex-end;
  font-size: 16px;
  border: 1px solid #fff;;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.toggle-button:hover {
  transform: scale(1.1);
}

.web-toggle {
  position: fixed;
  bottom: 20px;
  left: 10px;
}

.mobile-toggle {
  position: fixed;
  top: 20px;
  right: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (hover: hover) {
  .sidenav:not(.expanded):hover {
    width: 240px;
  }

  .sidenav:not(.expanded):hover .nav-text {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .sidenav {
    width: 0;
    position: fixed;
    padding-top: 40px;
    top: 0;
    right: 0;
    height: 100vh;
    overflow-y: auto;
    transition: width var(--transition-speed) ease-in-out;
  }

  .sidenav.expanded {
    width: 240px;
  }

  body {
    transition: margin-right var(--transition-speed) ease-in-out;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 997;
  }

  .web-toggle {
    display: none;
  }

  .nav-item {
    padding: 15px 20px;
  }

  .nav-text {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (min-width: 769px) {
  .mobile-toggle {
    display: none;
  }
}