.search-container {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.search-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 4px;
    overflow: hidden;
    width: 30%;
    background-color: #efefef;
}

.search-input {
    padding: 11px;
    flex-grow: 1;
    font-size: 13px;
    font-weight: 300;
    background-color: #f2f2f2;
    border: 1px solid #f1f1f1;
}

.search-input:focus {
    outline: none;
}

.search-button {
    background-color: #3e67be;
    border: 2px solid #3e67be;
    padding: 12px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 100%;
}

.search-button:hover {
    background-color: #365899;
}

.search-button .fa-search {
    font-size: 16px;
}

.suggestions-container {
    position: fixed;
    border: 1px solid #d4d4d4;
    background-color: #fff;
    z-index: 99;
    max-height: 200px;
    overflow-y: auto;
}

.suggestions-container a {
    padding: 10px;
    cursor: pointer;
    display: block;
    font-size: 16px;
    border-bottom: 1px solid #d4d4d4;
    text-decoration: none;
    color: #000;
}

.suggestions-container a:hover {
    background-color: #e9e9e9;
}

.suggestions-container a:last-child {
    border-bottom: none;
}

@media (max-width: 768px) {
    .search-wrapper {
        width: 100%;
        margin-top: 15px;
    }
}