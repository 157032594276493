@keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInDown {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .success-metric {
    animation: slideInUp 0.5s ease-out;
  }
  
  .slide-left {
    animation: slideInLeft 0.5s ease-out;
  }
  
  .slide-right {
    animation: slideInRight 0.5s ease-out;
  }
  
  .slide-up {
    animation: slideInUp 0.5s ease-out;
  }
  
  .slide-down {
    animation: slideInDown 0.5s ease-out;
  }
  