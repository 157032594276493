.EmiInformation {
  margin: 20px 0;
}

.EmiText {
  font-weight: bold;
  margin-bottom: 15px;
  text-align: end;
  margin-top: -25px;
  color: #3e67be;
}

.EmiIcon {
  margin-right: 5px;
}

.EmiProgressContainer {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  width: 100%;
}

.EmiStep {
  flex: 1;
  position: relative;
}

.EmiStepContent {
  display: flex;
  align-items: center;
}

.EmiStepMarker {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.EmiStepNumber {
  color: white;
  font-weight: bold;
}

.EmiStepActive .EmiStepMarker {
  background-color: #3e67be;
}

.EmiStepLabel {
  text-align: left;
}

.EmiStepTitle {
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.EmiStepSubtitle {
  color: #666;
  font-size: 0.9em;
}

.EmiStep:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 25px;
  left: 28px;
  right: 0;
  height: 2px;
  background-color: #ccc;
  z-index: 1;
}

.EmiStepActive:not(:last-child)::after {
  background-color: #3e67be;
}

@media (max-width: 768px) {
  .EmiProgressContainer {
    flex-direction: column;
  }

  .EmiStep {
    margin-bottom: 20px;
  }

  .EmiStep:not(:last-child)::after {
    display: none;
  }

  .EmiText {
    align-items: end;
    margin-bottom: 10px;
  }
}