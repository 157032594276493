.HierarchyHorizontalDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
.OneHierarchyHorizontalDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
.HierarchyIcon{
    width: 40px;
}