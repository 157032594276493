.overlay {
  position: absolute; /* Position it relative to its parent */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 23%);
  backdrop-filter: blur(8px); /* Background blur effect */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100; /* Ensure it is above other elements */
  border-radius: 4px;
}

.overlayContent {
  text-align: center;
  margin-top: 10px;
}

.payEmiButton {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #4267b2;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  margin-top: 6px;
}

.lockMessageText{
    margin-top:100px;
    color: red;
    font-weight: bold;
}

.courseLockedText{
    color: #ffffff;
    font-weight: bold;
    position: absolute;
    top: 10px;
    left: 18px;
    background-color: #4c4a4a;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
}
