.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.view-all-btn {
  background-color: #fff;
  color: #6078aa;
  padding: 10px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.4s ease;
  font-size: 14px;
  font-weight: 500;
}

.view-all-btn:hover {
  background-color: #4267B2;
  color: white;
}

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.section-title {
  font-size: 20px;
}

.carousel-container {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

.carousel-item {
  flex: 0 0 calc(100% / 6);
  scroll-snap-align: start;
  padding: 0 5px;
  box-sizing: border-box;
}

.carousel-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.carousel-item:hover img {
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10;
  transition: background-color 0.3s ease, opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-control:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel-control.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

/* additional of sale ribbon @Author satyam*/
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.carousel-item {
  position: relative;
  overflow: hidden;
}

.ribbon {
  background-image: url(https://www.drishtiias.com/images/uploads/1727157157_Group%201321315019@3x.png);
  background-size: cover;
  width: 60px;
  height: 60px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  right: 4px;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 1px solid #fff;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 95px;
  padding: 0px 0px;
  color: #fff;
  font: 700 10px / 2 'Lato', sans-serif;
  text-transform: uppercase;
  text-align: center;
  right: -26px;
  top: 10px;
  transform: rotate(45deg);
  animation: blink 1s linear infinite;
}


.ribbon::before {
  top: 0;
  right: 0;
}

.ribbon::after {
  bottom: 0;
  left: 0;
}

.ribbon::before {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}

/* end */
@media (max-width: 767px) {
  
  .carousel-item {
    flex: 0 0 40%;
    max-width: 70%;
  }

  .carousel-control {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}