.am-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.am-overlay--visible {
  opacity: 1;
  visibility: visible;
}

.am-content {
  background-color: #fff;
  width: 100%;
  max-width: 430px;
  height: 100%;
  padding: 1rem;
  overflow-y: auto;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.am-content--visible {
  transform: translateX(0);
}

.am-close-btn {
  position: absolute;
  top: 8rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
  color: #333;
}

.am-address-section {
  margin-bottom: 2rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.am-address-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f8f8;
  cursor: pointer;
}

.am-toggle-icon {
  transition: transform 0.3s ease;
}

.am-toggle-icon--collapsed {
  transform: rotate(-90deg);
}

.am-address-list {
  max-height: 300px;
  overflow-y: auto;
}

.am-address-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
  transition: background-color 0.2s ease;
}

.am-address-item--selected {
  background-color: #f0f8ff;
  border: 1px solid #4267B2;
}

.am-address-details {
  flex-grow: 1;
}

.am-select-btn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #333;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.am-select-btn--selected {
  background-color: #4267B2;
  color: white;
  border-color: #4267B2;
}

.am-select-btn:hover {
  background-color: #e0e0e0;
}

.am-select-btn--selected:hover {
  background-color: #3A80D2;
}

.am-form {
  display: flex;
  flex-direction: column;
}

.am-input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.am-form-row {
  display: flex;
  gap: 1rem;
}

.am-form-row .am-input {
  flex: 1;
}

.am-submit-btn {
  background-color: #4267B2;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.am-submit-btn:hover {
  background-color: #3A80D2;
}