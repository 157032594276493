.order-confirmation {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
}

.confirmation-gif {
    margin: 0 auto 20px;
    width: 100px;
    height: 100px;
}

.confirmation-gif img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.go-to-orders-btn {
    background-color: #4f6cbb;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.go-to-orders-btn:hover {
    background-color: #4f6ce4;
}