.footer-container{
    padding: 100px;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 50px;
}

.text-blue-300 {
    --tw-text-opacity: 1;
    color: #f9f9f9c2;
}

@media (max-width: 768px) {
    .footer-container {
        padding: 10px;
    }
}