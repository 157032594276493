.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-logo {
  height: 55px;
  margin-left: 75px;
  margin-top: 8px;
}

.header-middle {
  flex-grow: 1;
  display: inline-flex;
  justify-content: center;
}

.search-container {
  display: contents;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.search-dropdown {
  padding: 11px;
  background-color: #fff;
  cursor: pointer;
  margin-right: 16px;
  border: 1px solid #4267b2;
  border-radius: 4px;
  margin-top: 1px;
}

.search-dropdown:focus {
  outline: none;
}

.header-right {
  display: flex;
  align-items: center;
}

.login-signup-button {
  background-color: #4267B2;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-width: 160px;
  margin-right: 80px
}

.login-signup-button:hover {
  background-color: #365899;
}

@media (max-width: 768px) {

  .header-logo {
    margin-left: 0px;
  }

  .header-left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .header-middle {
    display: ruby;
  }

  .header-container {
    display: inherit;
    padding: 8px;
  }

  .login-signup-button {
    margin-top: 10px;
    width: 100%;
    margin-right: 0px;
  }
}

.ProfilePhoto {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid #4267b2;
}

.ProfilePhotoAndNameDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 2px 75px 5px 10px;
}

.LogoutText {
  color: #365899;
  font-weight: bold;
  text-align: end;
  cursor: pointer;
  font-size: 14px;
}

.ProfileNameAndLogoutDiv {
  /* display: flex; */
  flex-direction: column;
}

.header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-menu {
  display: flex;
  align-items: center;
}

.search-wrapper {
  margin: 0 1rem;
}

.user-section {
  display: flex;
  align-items: center;
}

.user-profile {
  display: flex;
  align-items: center;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-weight: bold;
}

.logout-button, .login-button {
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.5rem;
}

.logout-button:hover, .login-button:hover {
  color: #4267b2;
}

.mobile-menu-toggle {
  display: none;
}

.LogoutButton {
  background: #4267b2;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 5px 34px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s, transform 0.3s;
}

.LogoutButton:hover {
  background: #4267b2;
  transform: scale(1.05);
}

.LogoutButton:focus {
  outline: none;
}

.LogoutButton:active {
  background: #8998b6;
}


@media (max-width: 768px) {

  .ProfilePhotoAndNameDiv {
    padding: 0px;
  }

  .mobile-menu-toggle {
    display: block;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .nav-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #ffffff;
    flex-direction: column;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: none;
  }

  .nav-menu.open {
    display: flex;
  }

  .search-wrapper {
    width: 100%;
    margin: 1rem 0;
  }

  .user-section {
    display: none;
  }
}
