.ProductInformation {
  max-width: 1300px;
  margin: 0 auto;
  padding: 15px;
  font-family: Arial, sans-serif;
  animation: fadeIn 0.5s ease-out;
}

.CourseHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #cee5f5;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.CourseHeader:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.tabContainer {
  position: relative;
  transition: opacity 0.3s ease;
}

.tabContainer::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.tabContainer:hover::after {
  transform: scaleX(1);
}

@media (max-width: 768px) {
  .ProductInformation {
    max-width: 90%;
  }

  .CourseHeader {
    flex-direction: column;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.button {
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.button:hover {
  transform: translateY(-2px);
}

.button:active {
  transform: translateY(1px);
}

.input {
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.transition-element {
  transition: all 0.3s ease;
}