.BuyNowButton {
  background-color: #fff;
  border: 1px solid #4267b2;
  color: #4267b2;
  padding: 15px 20px;
  font-weight: 550;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 5px;
}

.BuyNowButton:hover{
background-color: #3e67be;
color: #fff;
}

@media (max-width: 768px) {
  .BuyNowButton {
    width: 100%;
  }
}