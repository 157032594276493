.orderDetails {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.orderCard {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.orderHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.orderInfo {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.label {
  font-weight: bold;
  color: #555;
}

.value {
  color: #000;
  margin-left: 3px;
}

.orderActions {
  text-align: right;
  flex: 1;
}

.orderId {
  font-weight: bold;
  color: #333;
}

.viewInvoice {
  color: #1a73e8;
  text-decoration: none;
}

.noInvoice {
  color: #999;
}

.courseContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.courseImage img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.courseDetails {
  flex: 1;
  text-align: end;
}

.productTitle {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

@media (max-width: 768px) {
  .courseDetails{
    text-align: start;
  }
  .orderCard {
    padding: 15px;
  }

  .orderHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .orderInfo {
    gap: 10px;
  }

  .orderActions {
    text-align: left;
    margin-top: 10px;
    width: 100%;
  }

  .courseContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .courseDetails {
    width: 100%;
  }

  .productTitle {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

@media (max-width: 480px) {
  .orderCard {
    padding: 30px;
  }

  .orderHeader {
    margin-bottom: 15px;
  }

  .orderInfo {
    flex-direction: column;
    gap: 5px;
  }

  .courseImage img {
        width: 100%;
        height: 100%;
  }

  .productTitle {
    font-size: 14px;
  }

  .value {
    font-size: 14px;
  }

  .orderId {
    font-size: 14px;
  }
}
