.spinner-container {
  position: fixed;
  top: 0; 
  left: 0; 
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px); 
  z-index: 9999;
}

.spinner {
  position: relative;
  width: 50%;
  max-width: 100px;
}

@media screen and (max-width: 768px) {
  .spinner {
      width: 70%;
  }
}

@media screen and (max-width: 480px) {
  .spinner {
      width: 90%;
  }
}