.otpVerification {
  text-align: center;
  width: 100%;
  padding: 20px;
  position: relative;
}

.logo {
  display: flex;
  justify-content: center;
}

.logo img {
  width: 60px;
  margin-bottom: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.SocialCaseprogressBar, .progressBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.SocialCaseprogressBar::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #3e67be;
  z-index: 1; /* Lower z-index */
  transform: translateY(-50%);
}

.progressBar::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ddd;
  z-index: 1; /* Lower z-index */
  transform: translateY(-50%);
}

.SocialCaseprogressBar::after, .progressBar::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  background-color: #3e67be;
  z-index: 2; /* Lower z-index than the steps */
  width: 50%; /* Adjust this percentage according to the current step */
  transform: translateY(-50%);
}

.step {
  position: relative;
  z-index: 3; /* Higher z-index to ensure it's on top of the lines */
  background-color: #acacac;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #ffffff;
}

.step.active {
  background-color: #3e67be;
  border: none;
  color: #ffffff;
}

.step.completed {
  background-color: #3e67be;
  border: none;
  color: #ffffff;
}

.stepLabel {
  margin-top: 8px;
  font-size: 12px;
  color: #777;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.stepLabel span {
  width: 33%;
}

.stepLabel .active {
  color: #3e67be;
}

.stepLabel .completed {
  color: #3e67be;
}

.verificationStep {
  width: 100%;
}

.verificationHeadingText {
  display: block;
  font-size: 12px;
  margin-top: 24px;
  text-align: left;
  color: #999999;
}

.ResendOtpEnabledText {
  font-weight: bold;
  color: #3e67be;
  text-align: right;
  margin-top: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  font-size: 14px;
}

.ResendOtpDisabledText {
  color: #1f1f1f;
  text-align: right;
  margin-top: 8px;
  display: flex;
  flex-direction: row-reverse;
}

.VerifyOtpButton {
  cursor: pointer;
  background-color: #3e67be;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  margin: 18px 0;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
}

.errorMessage {
  color: red;
  margin-top: 10px;
}

.Step1Text {
  text-align: left;
  padding-left: 12px;
}

.Step3Text {
  text-align: right;
}