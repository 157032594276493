.PayEmiButton {
  background-color: #fff;
  border: 1px solid #4267b2;
  color: #4267b2;
  padding: 15px 20px;
  font-weight: 550;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 5px;
}

.PayEmiButton:hover {
  background-color: #3e67be;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.PayEmiButton:active {
  background-color: #2471a3;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transform: translateY(1px);
}

.PayEmiButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.5);
}