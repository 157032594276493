.CourseTitleText, .PriceText{
    text-align: end;
}

.PriceText{
    margin-bottom: 35px;
}

@media (max-width: 768px) {
    .PrimaryButtons {
        display: inline !important;
    }
    .CourseTitleText, .PriceText{
        text-align: start;
    }

    .CoursePricingDiv h3 {
        text-align: left !important;
    }

    .CoursePricingDiv {
        text-align: left;
    }
    .CoursePricingDiv {
        flex: 1 1 100%;
    }
}

.OriginalPriceText {
    text-decoration: line-through;
    color: #888;
    font-size: 0.8em;
    text-align: center;
}

.PrimaryButtons {
    display: ruby;
    text-align: end !important;
}

.CoursePricingDiv {
flex: 1 1 220px;
padding: 20px;
display: flex;
flex-direction: column;
justify-content: center;
}

.CoursePricingDiv h3 {
margin-bottom: 10px;
color: #333;
text-align: right;
}