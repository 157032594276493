.myCartDiv {
  max-width: 1050px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.myCartHeading {
  margin-bottom: 20px;
  color: #333;
}

.cartItemDiv {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  opacity: 1;
  transform: translateX(0);
}

.cartItemDiv.removing {
  opacity: 0;
  transform: translateX(-100%);
}

.emptyCartDiv {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
  animation: fadeIn 0.5s ease;
}

.itemLeft {
  padding: 10px;
  width: 30%;
  flex-grow: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.itemLeftH3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.itemLeftP {
  margin: 5px 0;
  color: #666;
}

.instructor {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.instructorImg {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  margin-right: 10px;
}

.instructorSpan {
  font-size: 14px;
  color: #333;
}

.live-tag,
.language-tag {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 5px;
}

.live-tag {
  background-color: #ff4444;
  color: white;
}

.itemRight {
  padding: 0px 20px 0px 6px;
  width: 55%;
  flex-grow: 3;
}

.itemRightH3 {
  margin: 20px -10px 15px 5px;
  font-size: 18px;
  color: #333;
}

.itemDetails {
  display: inline;
  grid-template-columns: auto auto;
  gap: 10px;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.detailRowSpan:first-child {
  color: #3f3f3f;
  font-weight: 800;
}

.detailRowSpan:last-child {
  color: #1f1f1f;
  font-weight: 500;
}

.itemPrice {
  margin-top: 15px;
  text-align: left;
  font-size: 22px;
}

.originalPrice {
  text-decoration: line-through;
  color: #888;
  margin: 0;
  font-size: 22px;
}

.discountedPrice {
  font-weight: bold;
  color: #4f6cbb;
  font-size: 26px;
  margin: 5px 5px 0 0;
  float: right;
}

.removeBtn {
  font-weight: 600;
  background: none;
  border: none;
  color: #3e67be;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  transition: color 0.2s ease;
}

.removeBtn:hover {
  color: #ff6b6b;
}

.removeBtnSvg {
  margin-right: 5px;
  transition: transform 0.2s ease;
}

.removeBtn:hover .removeBtnSvg {
  transform: scale(1.1);
}

.checkoutButton {
  background-color: #4f6cbb;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  float: right;
  font-weight: bold;
  transition: background-color 0.2s ease, transform 0.3s ease;
  margin-top:-10px;
}

.checkoutButton:hover {
  background-color: #8a9bc9;
  transform: scale(1.05);
}

.checkoutButton:active {
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .cartItemDiv {
    flex-direction: column;
    padding: 15px;
  }

  .itemLeft,
  .itemRight {
    width: 100%;
  }

  .removeBtn {
    position: static;
    margin-top: 15px;
    justify-content: center;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.2s ease;
}

.confirmationDialog {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 400px;
  width: 100%;
  text-align: center;
  animation: slideIn 0.3s ease;
}

.confirmationDialogButtonDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirmationDialogButtonNo {
  cursor: pointer;
  padding: 15px 78px;
  border-radius: 4px;
  border: none;
  background-color: #4f6cbb;
  color: white;
  transition: background-color 0.3s ease;
}

.confirmationDialogButtonYes {
  cursor: pointer;
  padding: 15px 75px;
  border-radius: 4px;
  border: 1px solid #4f6cbb;
  background-color: #fff;
  color: #4f6cbb;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.confirmationDialogButtonYes:hover {
  background-color: #8a9bc9;
  color: #fff;
}

.confirmationDialogButtonNo:hover {
  background-color: #8a9bc9;
}

.itemDeleteDiv {
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
  padding: 10px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}