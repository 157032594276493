.courseFeatures {
    width: 100%;
    overflow: hidden;
  }
  
  .AllTabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    padding: 10px;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .AllTabs::-webkit-scrollbar {
    display: none;
  }
  
  .OneTab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border: none;
    background: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 8px;
    min-width: 120px;
    height: 100px;
  }
  
  .OneTabActive {
    background: #f0f4ff;
    color: #3e67be;
    border-bottom: 3px solid #3e67be;
    font-weight: bold;
  }
  
  .OneTabIcon {
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
    object-fit: contain;
  }
  
  .OneTabName {
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
  }
  
  @media (max-width: 768px) {
    .AllTabs {
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;
      padding-bottom: 15px;
    }
  
    .OneTab {
      flex-shrink: 0;
    }
  }
  
  @media (max-width: 480px) {
    .OneTab {
      min-width: 100px;
      height: 90px;
      padding: 10px;
    }
  
    .OneTabIcon {
      width: 24px;
      height: 24px;
    }
  
    .OneTabName {
      font-size: 12px;
    }
  }
  
  .featureContent {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }